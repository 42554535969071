import classes from "./SectionHomepage.module.scss";
import productShowcaseSrc from "./../../images/productShowcase.png";
import dropdownDownSrc from "./../../images/dropdownDown.png";
// import EmailAccept from "../../UI/EmailAccept";
import BuyButton from "../../UI/BuyButton";
import { useState } from "react";

const SectionOne = (props) => {
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const agencies = [
    "Web Designing Agency",
    "Mobile App Designing Agency",
    "Web Development Agency",
    "Mobile App Development Agency",
    "Digital Marketing Agency",
    "Social Media Agency",
    "SEO Agency",
    "Data Extraction and Analytics Agency",
    "Email Marketing Agency",
    "Advertising Agency",
    "E-commerce Agency",
    "Blockchain Solutions Agency",
    "Lead Generation Agency",
    "Automation Agency",
  ]; // Replace with your agency options

  const handleAgencySelect = (agency) => {
    setSelectedAgency(agency);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <section className={classes.section__homepage}>
      <div className={classes.headings}>
        <h1 className={classes.primaryHeading}>
          1000 Verified Leads for your agency Delivered directly to your 📬
        </h1>
        <h3 className={classes.secondaryHeading}>
          and not more than 20 people seeing them at a time
        </h3>
      </div>

      <div className={classes.comparison_block}>
        <span className={classes.comparison_block__heading}>
          Before TopPriorityLeads:
        </span>
        Other online leads are not suitable to your business, 100’s of people
        seeing that at one time, and alot of them are unverified
      </div>
      <div className={classes.comparison_block}>
        <span className={classes.comparison_block__heading}>
          After TopPriorityLeads:
        </span>
        High-potential handpicked custom leads that recently got funding, No
        more than 20 people, including you, see the leads and Every single lead
        is verified.
      </div>
      {/* <div className={classes.productShowcase}>
        <img
          src={productShowcaseSrc}
          alt="Product Showcase"
          className={classes.productShowcaseImage}
        ></img>
      </div> */}
      <div className={classes.emailInput}>
        {/* <EmailAccept></EmailAccept> */}
        <div className={classes.selectAgency}>
          <div className={classes.dropdown}>
            <button
              className={`${classes.dropdownButton} ${
                isDropdownOpen ? classes.active : ""
              }`}
              // onClick={toggleDropdown}
            >
              {selectedAgency || "Select Agency"}{" "}
              <img
                src={dropdownDownSrc} // Replace with the actual path to your arrow image
                alt="Dropdown Arrow"
                className={isDropdownOpen ? classes.arrowUp : classes.arrowDown}
              />
            </button>
            <ul
              className={`${classes.dropdownList} ${
                isDropdownOpen ? classes.open : ""
              }`}
            >
              {agencies.map((agency, index) => (
                <li
                  key={index}
                  className={classes.dropdownListItem}
                  onClick={() => handleAgencySelect(agency)}
                >
                  {agency}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <BuyButton></BuyButton>
      </div>
    </section>
  );
};

export default SectionOne;
