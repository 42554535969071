import classes from "./DemoVideo.module.scss";
import { useState } from "react";
import demoVideoSrc from "./../../images/getOData_demo.mp4";

function DemoVideo() {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayback = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <section className={classes.demoVideo}>
      <h2 className={classes["demoVideoHeading"]}>Demo Video ⚡</h2>
      <div className={classes.demoVideo__main}>
        <video controls={true} className={classes.demoVideo_src}>
          <source src={demoVideoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <button onClick={togglePlayback}>{isPlaying ? "Pause" : "Play"}</button> */}
      </div>
    </section>
  );
}

export default DemoVideo;
