import styles from "./SectionFeatures.module.scss";
import productShowcaseSrc from "./../../images/productShowcase.png";
import HandpickedLeads from "./../../images/HandpickedLeads.png";
import LimitedUsers from "./../../images/LimitedUsers.png";
import VerifiedLeads from "./../../images/VerifiedLeads.png";
// import feature_1_src from "./../../images/feature_1.png";
import feature_1_src from "./../../images/feature-1.png";
import feature_2_src from "./../../images/feature-2.png";
import feature_3_src from "./../../images/feature-3.png";

const SectionFeatures = (props) => {
  return (
    <section className={styles.section_project_details}>
      <h3 className={styles["section-portfolio__title"]}>
        What's Different About us? 🔥
      </h3>
      <h3 className={styles["section-portfolio__mainTitle"]}>
        Get Leads that are catered according to your business
      </h3>

      {/* Tromodoro */}
      <div className={styles["project-details"]}>
        <div className={styles["project-info"]}>
          <div className={styles["project-info__description"]}>
            <img
              src={HandpickedLeads}
              alt="Project feature"
              className={styles.project_info_image}
            ></img>
            <div className={styles["project-info__title"]}>
              Handpicked Leads
            </div>
            <div className={styles.description__text}>
              Each and every lead is handpicked and carefully picked according
              to your Business.
            </div>
          </div>
        </div>
        <div className={styles["project-info"]}>
          <div className={styles["project-info__description"]}>
            <img
              src={LimitedUsers}
              alt="Project feature"
              className={styles.project_info_image}
            ></img>
            <div className={styles["project-info__title"]}>Limited Users</div>
            <div className={styles.description__text}>
              Other online leads sell the same leads to hundreds of users. Not
              us. No more than 20 people, including you, see the leads.
            </div>
          </div>
        </div>
        <div className={styles["project-info"]}>
          <div className={styles["project-info__description"]}>
            <img
              src={VerifiedLeads}
              alt="Project feature"
              className={styles.project_info_image}
            ></img>
            <div className={styles["project-info__title"]}>Verified Leads</div>
            <div className={styles.description__text}>
              Each and every leads is validated so that your message always
              reaches to your potential Customers.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFeatures;
