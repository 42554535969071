import classes from "./SectionTestimonials.module.scss";
import testimonialOneSrc from "./../../images/testimonial_1.png";

function SectionTestimonials() {
  return (
    <section className={classes.SectionTestimonials}>
      <h1 className={classes.tesimonialHeading}>
        Here are Some Rave Reviews from Tech Experts about Our Product
      </h1>
      <div className={classes.testimonialImages}>
        <img
          src={testimonialOneSrc}
          alt="Testimonial 1"
          className={classes.tes_img}
        ></img>
        <img
          src={testimonialOneSrc}
          alt="Testimonial 1"
          className={classes.tes_img}
        ></img>
        <img
          src={testimonialOneSrc}
          alt="Testimonial 1"
          className={classes.tes_img}
        ></img>
      </div>
    </section>
  );
}

export default SectionTestimonials;
