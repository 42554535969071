import Footer from "./../components/Footer";
import { Fragment } from "react";
import SectionOne from "../components/homepage/SectionHomepage";
import SectionFeatures from "../components/homepage/SectionFeatures";
import FAQ from "../components/homepage/FAQ";
import SectionSignUp from "../components/homepage/SectionSignUp";
import { useEffect } from "react";
import WallOfLove from "../components/homepage/WallOfLove";
import DemoVideo from "../components/homepage/DemoVideo";
import SectionTestimonials from "../components/homepage/SectionTestimonials";

const Home = () => {
  return (
    <Fragment>
      <SectionOne />

      <SectionTestimonials></SectionTestimonials>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="1440"
        // height="189"
        viewBox="0 0 1440 189"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3 189V0.206373C152.173 27.9506 417.8 46.2406 719.424 46.2406C1021.8 46.2406 1287.99 27.8598 1443 0V189H1435.9C1279.94 161.845 1017.23 144.015 719.424 144.015C421.619 144.015 158.904 161.845 2.94627 189H-3Z"
          fill="url(#paint0_linear_853_51)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_853_51"
            x1="-3"
            y1="21.4773"
            x2="1347.63"
            y2="473.781"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9898" />
            <stop offset="1" stopColor="#8054FF" />
          </linearGradient>
        </defs>
      </svg>
      <SectionFeatures></SectionFeatures>
      {/* <DemoVideo></DemoVideo> */}
      <FAQ></FAQ>
      {/* <WallOfLove></WallOfLove> */}

      <SectionSignUp></SectionSignUp>
      <Footer></Footer>
    </Fragment>
  );
};

export default Home;
