import styles from "./FAQ.module.scss";
import { useState } from "react";

const questions = [
  {
    id: 1,
    question: "What's different about you from Other online Leads?",
    answer:
      "Other online leads you see in the Market cater to hundreds of users. This reduces the chances of you converting the leads. But that doesn't happen with us. Our Leads are custom to your needs, no more than 20 people see them at a time other than you and each of it is verified.",
  },
  {
    id: 2,
    question:
      "Do you take feedback on the type of leads we need and re-iterate every month?",
    answer:
      "Yes, We actually are looking to hear your needs and find the leads according to your business. This is one of the points which seperates us from others.",
  },
  {
    id: 3,
    question: "What are your sources for the leads?",
    answer:
      "We get leads across the internet after thorough research. We generally look for recently funded companies and get the contact details of the Top management at those companies (CEO, Co-founder's & CTO's). By our research, these help us get the leads which have the highest chances of converting!",
  },
  {
    id: 4,
    question:
      "How do you manage the leads and keep it catered according to our needs?",
    answer:
      "We manually collect the leads according to your business needs. Since the number of users will not be more than 20, we get to listen to every user's need and collect the lead which has the maximum potential of turning the leads into customers. We collect monthly feedback from our users which help us collect leads that our Users are looking for!",
  },
];

const FAQ = (props) => {
  const [clickedQuestionIndex, setClickedQuestionIndex] = useState(null);

  const handleQuestionClick = (index) => {
    if (clickedQuestionIndex === index) {
      setClickedQuestionIndex(null);
    } else {
      setClickedQuestionIndex(index);
    }
  };

  return (
    <section className={styles.sectionFAQ}>
      {/* <h2 className={styles["section-FAQ__title"]}>
        Frequently asked questions
      </h2> */}
      <div className={styles.faqSection}>
        <div className={styles.questionsContainer}>
          <h2 className={styles.faqHeading}>Frequently asked questions</h2>
          {questions.map((question, index) => (
            <div className={styles.question} key={index}>
              <h3 onClick={() => handleQuestionClick(index)}>
                {question.question}
              </h3>
              {clickedQuestionIndex === index && <p>{question.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
